body {
  margin: 0;
  padding: 0;
  height: 100vh;
  width: 100%;
}
.projectimg img {
  height: 300px !important;
  width: 100% !important;
  object-fit: cover !important;
}
